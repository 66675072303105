// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.
import consumer from "./consumer";
import Swal from "sweetalert2";

consumer.subscriptions.create("WebNotificationChannel", {
  connected() {
    console.log(`connected to web notifications`);
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.status === 500) {
      let html;
      if (data.csv_url && data.csv_url.toString().length > 0) {
        html = `but the csv still worked - <a href="${data.csv_url}">CSV</a> Err: ${data.message} `;
      } else {
        html = `and the csv failed too - ${data.message}`;
      }
      Swal.fire({
        title: data.title,
        html,
        icon: "error",
        confirmButtonText: "Darn",
      });
    } else {
      Swal.fire({
        title: data.title,
        html: `${data.message}<br \>
        <a href="${data.url}" target="_blank">Google Sheets</a> <a href="${data.csv_url}" target="_blank">CSV</a>
      `,
        icon: "success",
        confirmButtonText: "Got it",
      });
    }

    console.log(data);
    // Called when there's incoming data on the websocket for this channel
  },
});
