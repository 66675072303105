require("@rails/ujs").start();
require("@rails/activestorage").start();
import "../channels";
import "./stylesheets/application.css";
//import "popper.js";
import "@hotwired/turbo-rails";

//import "bootstrap-icons/font/bootstrap-icons.css";

import { Turbo } from "@hotwired/turbo-rails";
window.Turbo = Turbo;
